<template>
<div class="kt-grid kt-grid--ver kt-grid--root kt-page">
    <h5 class="kt-font-bold m-5">
        Logging out of Syncgrades{{ ellipsis }}
        <br><br>
        Please wait{{ ellipsis }}
    </h5>

    <iframe
        v-if="singleSignOutUrl"
        :src="singleSignOutUrl"
        :style="hideFrame ? 'position: absolute; width:0; height:0; border:0;' : ''"
    />
</div>
</template>

<script>
import Vue from 'vue';
import * as net from '../../network';
import globalMixins from '../../store/mixins/globalMixins';

export default Vue.extend({
    name: 'UserLogout',
    components: {},
    mixins: [globalMixins],
    data() {
        return {
            seconds: 0,
            userName: null,
            singleSignOutUrl: null,
            hideFrame: true,
        };
    },
    computed: {
        ellipsis(state) {
            if (state.seconds === 4) return '....';
            if (state.seconds === 3) return '...';
            if (state.seconds === 2) return '..';
            if (state.seconds === 1) return '.';
            return '';
        },
        isNYCDoe(state) {
            return Boolean(state.userName && state.userName.indexOf('@schools.nyc.gov') > -1);
        },
    },
    mounted() {
        const v = this;
        setInterval(() => {
            if (v.seconds > 4) {
                v.seconds = 1;
                return;
            }
            v.seconds += 1;
        }, 1000);

        setTimeout(() => {
            v.logout();
        }, 2000);
    },
    methods: {
        logout() {
            const { storageClear, showError } = this;
            storageClear(() => {
                net.session.terminate({}, (logOutErr) => {
                    if (logOutErr) return showError(logOutErr);
                    window.location.href = '/login';
                });
            });
        },
    },
});
</script>
