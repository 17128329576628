var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "kt-grid kt-grid--ver kt-grid--root kt-page" },
    [
      _c("h5", { staticClass: "kt-font-bold m-5" }, [
        _vm._v(" Logging out of Syncgrades" + _vm._s(_vm.ellipsis) + " "),
        _c("br"),
        _c("br"),
        _vm._v(" Please wait" + _vm._s(_vm.ellipsis) + " "),
      ]),
      _vm.singleSignOutUrl
        ? _c("iframe", {
            style: _vm.hideFrame
              ? "position: absolute; width:0; height:0; border:0;"
              : "",
            attrs: { src: _vm.singleSignOutUrl },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }